import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Heading = styled.div`
  margin-bottom: 40px;
  display: flex;
`;

export const HeadingImage = styled.img`
  display: block;
  width: 90px;
  height: 90px;

  @media (min-width: 768px) {
    width: 130px;
    height: 130px;
  }
`;

export const HeadingTitles = styled.div`
  flex: 1;
  padding: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 0 0 0 40px;
  }
`;

export const PostTitle = styled.h1`
  font-family: Thasadith;
  font-size: 2rem;
  margin-bottom: .8rem;

  @media (min-width: 768px) {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
`;

export const PostAuthor = styled.h1`
  font-family: Thasadith;
  font-size: 2rem;
  color: ${c.grayText};

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const Paper = styled.div`
  background-color: ${c.grayPaper};
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: 768px) {
    padding: 40px;
  }
`;

export const Citations = styled.div`
  padding: 40px 40px 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  p {
    font-size: 1.8rem;
    line-height: 148%;
    color: ${c.grayText};
    margin-bottom: 18px;
  }

  a {
    color: ${c.purpleText};
    text-decoration: none;
    // wrap long URLs
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: ${c.purpleText};
      text-decoration: none;
    }
  }
`;

export const Body = styled.div`

  p {
    font-size: 2.2rem;
    line-height: 148%;
    text-align: justify;
    margin-bottom: 18px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  blockquote {
    overflow-y: hidden;
    overflow-x: auto;
    font-size: 2.2rem;
    font-style: italic;
    line-height: 148%;
    text-align: justify;
    margin-bottom: 18px;
    padding: 0 26px 0 20px;
  }

  .gatsby-image-wrapper {
    width: 100%;
    margin-bottom: 10px;

    ${({ orientation }) => orientation === 'h' && `
      max-width: 240px;

      @media (min-width: 768px) {
        max-width: 350px;
      }

      @media (min-width: 992px) {
        max-width: 500px;
      }
    `}

    ${({ orientation }) => orientation !== 'h' && `
      max-width: 200px;

      @media (min-width: 768px) {
        max-width: 300px;
      }

      @media (min-width: 992px) {
        max-width: 430px;
      }
    `}

    @media (min-width: 768px) {
      float: right;
      margin: 0 0 10px 40px;
    }
  }
`;

export const MainImage = styled.img`
  width: 100%;
  margin-bottom: 10px;

  ${({ orientation }) => orientation === 'h' && `
    max-width: 240px;

    @media (min-width: 768px) {
      max-width: 350px;
    }

    @media (min-width: 992px) {
      max-width: 500px;
    }
  `}

  ${({ orientation }) => orientation !== 'h' && `
    max-width: 200px;

    @media (min-width: 768px) {
      max-width: 300px;
    }

    @media (min-width: 992px) {
      max-width: 430px;
    }
  `}

  @media (min-width: 768px) {
    float: right;
    padding: 0 0 10px 40px;
  }

  @media (min-width: 992px) {
    width: auto;
  }
`;
