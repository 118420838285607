import React from 'react'
// Components
import Block from '@sanity/block-content-to-react';
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container1200 } from '../../components/Container1200';
// Style
import {
  Body,
  Citations,
  Heading,
  HeadingImage,
  HeadingTitles,
  Paper,
  PostAuthor,
  PostTitle
} from './Post.styles';

export const query = graphql`
query ($slug: String) {
  sanityPost(slug: {current: {eq: $slug}}) {
    title
    mainImage {
      asset {
        fluid(maxWidth: 460) {
          ...GatsbySanityImageFluid
        }
      }
    }
    _rawCitations
    citations {
      _rawChildren
    }
    _rawBody
    body {
      _rawChildren
    }
    imageOrientation
    author {
      image {
        asset {
          fluid {
            src
          }
        }
      }
      name
    }
    imgAlt
    quote
    slug {
      current
    }
  }
}
`;

const Post = ({ data }) => {
  const post = data.sanityPost;

  const linkSerializer = {
    marks: {
      link: ({ mark,children }) => {
        return (
          <OutboundLink className="out" href={mark.href}>{children}</OutboundLink>
        )
      }
    }
  };

  return(
    <Layout>
      <SEO
        description={`Thoughts from ${post.author.name} - ${post.quote}`}
        path={`/post/${post.slug.current}`}
        image={post.mainImage.asset.fluid.src}
        title={post.title}
      />
      <Container1200>
         <Paper>
          <Heading>
            <HeadingImage
              alt={`portrait of ${post.author.name}`}
              orientation={post.imageOrientation}
              src={post.author.image.asset.fluid.src}
            />
            <HeadingTitles>
              <PostTitle>{post.title}</PostTitle>
              <PostAuthor>thoughts from {post.author.name}</PostAuthor>
            </HeadingTitles>
          </Heading>
          {post.body &&
            <Body>
              <Img
                fluid={post.mainImage.asset.fluid}
                alt={post.imgAlt}
              />
                <Block
                  blocks={post._rawBody}
                  projectId="xcfisis7"
                  dataset="production"
                />
            </Body>
          }
        </Paper>
          {post.citations &&
            <Citations>
              <Block
                blocks={post._rawCitations}
                projectId="xcfisis7"
                dataset="production"
                serializers={linkSerializer}
              />
            </Citations>
          }
      </Container1200>
    </Layout>
  );
}

export default Post